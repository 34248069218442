// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


/*******************
*      POP-UP
********************/

.md-content.popup-box {
    h3 {
        font-weight: bold;
        font-size: $font-size-48;
        color: #000;
        font-family: $font-family-1;
        text-transform: initial;
    }
    p {

    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#burst-texteImage {
    background: #eeeeee;
    padding: 0 5% 100px 5%;

    .blocBurst {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: -140px;
        z-index: 30;
        padding-bottom: 130px;
        .burst {
            background: #fff;
            border-radius: 15px;
            background-size: cover;
            width: 23%;
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
            .bloc {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: #000;
                    text-transform: uppercase;
                    margin: 0;
                    padding-top: 10px; 
                }
                h3 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: #000;
                }
            }
        }
        .burst.left:hover, .burst.center:hover, .burst.right:hover{
            transform: scale(1.05);
        }
        .burst.center {
            margin: 0 50px;
        }
    }
    .texte-image {
        display: flex;
        .left-side {
            text-align: right;
            width: 46%;
            margin-right: 150px;
            h2 {
                font-weight: bold;
                font-size: $font-size-46;
                color: #000;
                padding-bottom: 5px;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-48;
                color: #000;
                text-transform: initial;
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #000;
                margin: 0; 
                padding: 10px 0 50px 0;
            }
            a.btnLien {
                background: #18b0f6;
                color: #fff;
                font-weight: 600;
                text-transform: uppercase;
                font-size: $font-size-20;
                padding: 15px 30px;
                border-radius: 15px;
                transition: 0.2s;
                &:hover {
                    background: #0078cc;
                    transition: 0.2s;
                }
            }
        }
    }
}

#blocEquipe {
    display: flex;
    padding: 50px 5% 0 5%;
    margin-bottom: -105px;
    .left-side {
        width: 40%;
        margin-right: 170px;
    }
    .right-side {
        width: 45%;
        padding-top: 6%; 
        h3 {
            font-weight: 600;
            font-size: $font-size-48;
            color: #000;
            text-transform: initial;
            padding-bottom: 20px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #000;
            margin: 0;
        }
    }
}

#bloc50-50 {
    display: flex;
    min-height: 575px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 10%;
        left: 44.2%;
        width: 228px;
        height: 267px;
        background: url("../images/accueil_section04_icon01.png") no-repeat;
        z-index: 2;
    }
    .left-side {
        width: 50%;
        background: url("../images/accueil_section04_img01.jpg") no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        h3 {
            padding-right: 16%;
        }
    }
    .right-side {
        width: 50%;
        background: #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 7%;
        .liste {
            display: flex;
            .left {
                width: 50%;
                margin-right: 5%;
            }
            .right {
                width: 60%;
            }
        }
    }
    h3 {
        font-weight: 600;
        font-size: $font-size-48;
        color: #000;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #000;
        margin: 0 auto;
        width: 67%;
    }
    ul {
        li {
            font-weight: 600;
            font-size: $font-size-20;
            color: #000;
            line-height: 1.4;
            padding-bottom: 10px;
        }
    }
}

#blocVideo-fetes {
    display: flex;
    padding: 125px 3%;

    .video {
        margin-right: 150px;
    }
    .fetes {
        background: url("../images/accueil_section05_img02.jpg") no-repeat;
        background-size: cover;
        width: 50%;
        min-height: 490px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img-responsive {
            padding: 10px 0 10px 0;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-26;
            color: #000;
        }
        .bloc {
            p {
                font-weight: normal;
                font-size: $font-size-24;
                color: #000;
                margin: 0;
                line-height: 1.5;
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#blocServices {
    .bloc {
        display: flex;
        .left-side {
            width: 50%;
        }
        .right-side {
            width: 50%;
        }
    }
    .bloc.left {
        .right-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
        }
    }
    .bloc.right {
        .blocListe {
            display: flex;
            .left {
                width: 65%;
                ul {
                    padding-left: 7%;
                }
            }
        }
        .left-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 5%;
        }
    }
    h3 {
        font-weight: 600;
        font-size: $font-size-48;
        color: #000;
        padding-bottom: 35px;
        text-transform: initial;
    }
    ul {
        li {
            font-weight: 600;
            font-size: $font-size-20;
            color: #000;
            line-height: 1.5;
            padding-bottom: 10px;
        }
    }
}

#blocInfo {
    background: #eeeeee;
    padding: 100px 2%;
    text-align: center;
    h3 {
        font-weight: bold;
        font-size: $font-size-48;
        color: #000;
        text-transform: initial;
        padding-bottom: 10px;
    }
    h4 {
        font-weight: normal;
        font-size: $font-size-44;
        color: #000;
        padding-bottom: 45px;
    }
    a.btnLien {
        background: #18b0f6;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        font-size: $font-size-20;
        padding: 15px 30px;
        border-radius: 15px;
        transition: 0.2s;
        &:hover {
            background: #0078cc;
            transition: 0.2s;
        }
    }
}

/*******************************************************************************
* NOS INSTALLATIONS
*******************************************************************************/

#burst-texteImage {
    .blocIntro_installations {
        text-align: center;
        .texte.first {
            padding-bottom: 35px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #000;
            margin: 0;
        }
    }
}

#blocNosInstallations {
    padding: 100px 5%;
    h2 {
        font-weight: 600;
        font-size: $font-size-48;
        color: #000;
    }
    .blocListe {
        display: flex;
        padding-top: 35px;
        .left-side {
            width: 57%;
        }
        ul {
            li {
                font-weight: 600;
                font-size: $font-size-20;
                color: #000;
                line-height: 1.5;
                padding-bottom: 10px;
            }
        }
    }
}

#blocImages {
    display: flex;
    flex-wrap: wrap;
    a.image {
        width: 25%;
    }  
}

/*******************************************************************************
* ACTIVITÉS ET PHOTOS
*******************************************************************************/

#burst-texteImage {
    .blocIntro {
        text-align: center;
        h3 {
            font-weight: bold;
            font-size: $font-size-46;
            color: #000;
            line-height: 0.5;
        }
        p {
            font-weight: normal;
            font-size: $font-size-46;
            color: #000;
            margin: 0;
        }
    }
}

#blocActivites {
    padding: 100px 5%;
    .realisations.zoom-gallery {
        display: flex;
        flex-wrap: wrap;
        a.item {
            width: 33.3333%;
            padding: 35px;
            .bloc {
                .texte {
                    padding-top: 25px;
                    h3 {
                        font-weight: 600;
                        font-size: $font-size-48;
                        color: #000;
                        text-transform: initial;
                    }
                    h4 {
                        font-weight: normal;
                        font-size: $font-size-30;
                        color: #000;
                        padding-bottom: 15px;
                    }
                    p {
                        font-weight: 600;
                        font-size: $font-size-24;
                        color: #000;
                        margin: 0;
                        text-align: justify;
                        line-height: 1.5;
                    }
                }
            }
        }    
    }
}

/*******************************************************************************
* DEMANDE DE DISPONIBILITÉ
*******************************************************************************/

#blocFormulaire {
    padding: 100px 5%;
    .formulaireDemande {
        overflow: hidden;
        display: flex;
        position: relative;
        left: 7%;
        .left-side {
            width: 55%;
        }
        .right-side {
            width: 60%;
        }
        .left-side, .right-side {
            display: flex;
            flex-direction: column;
        }
    }  
    label.container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 5px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: inherit;
        padding-bottom: 20px;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border: 1px solid #000;
        border-radius: 5px;
    }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #18b0f6;
          border: none;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }
        .container .checkmark:after {
          left: 10px;
          top: 6px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
   
    .col-xs-12.col-sm-12.form-group.list-checkBox {
        padding-top: 10px;
        margin: 0;
    }
    label {
        color: #000;
        font-weight: 600;
        font-size: $font-size-26;
        text-transform: initial;
        margin: 0;
        span {
            font-size: $font-size-18;
            padding-bottom: 15px;
        }
    }
    label.container {
        color: #000;
        font-weight: normal;
        font-size: $font-size-24;
    }
    input {
        font-size: $font-size-24;
        border-radius: 15px;
    }
    input#autre_precision, input#autres_conditions_precision {
        margin: -25px -15px;
        width: 185%;
    }
    label.image {
        font-size: $font-size-24;
        border-bottom: 1px solid #000;
    }
    #right {
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        position: relative;
        left: -18.5%;
        align-items: center;
        .btn {
            background-color: #18b0f6;
            color: #fff;
            border: none;
            font-size: $font-size-24;
            width: 85%;
            border-radius: 10px;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoordonnees {
    padding: 100px 5%;
    display: flex;
    justify-content: space-around;

    .bloc {
        text-align: center;
        .img-responsive {
            margin: auto;
        }
        h3 {
            font-weight: 600;
            font-size: $font-size-48;
            color: #18b0f6;
            padding: 15px 0 5px 0;
            text-transform: initial;
        }
        p {
            font-weight: 600;
            font-size: $font-size-24;
            color: #000;
            margin: 0;
            line-height: 1.5;
        }
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1820px) {

#burst-texteImage .texte-image .left-side {
    width: 50%;
}
#bloc50-50::after {
    left: 43.8%;
}
#blocVideo-fetes {
    align-items: center;
}
#blocVideo-fetes .video iframe {
    width: 715px;
    height: 415px;
}
#blocFormulaire .formulaireDemande .right-side {
    width: 65%;
}
#blocFormulaire #right {
    left: -17.5%;
}
}

@media screen and (max-width: 1710px) {

#burst-texteImage .texte-image .left-side h2 {
    font-size: 2.1rem;
}
#blocEquipe .right-side {
    width: 50%;
    padding-top: 3%;
}
#bloc50-50::after {
    left: 43.5%;
}
#blocVideo-fetes .video {
    width: 40%;
}
#burst-texteImage  .blocBurst.remplissage {
    margin-top: -90px;
}
.module-header--caption {
    top: 53%;
}
#blocFormulaire .formulaireDemande .right-side {
    width: 70%;
}
#blocFormulaire .formulaireDemande .left-side {
    width: 60%;
}
#blocFormulaire .formulaireDemande {
    left: 1%;
}
#blocServices ul li {
    padding-bottom: 5px;
}
#blocServices h3 {
    padding-bottom: 25px;
}
}

@media screen and (max-width: 1650px) {

#blocServices .bloc.left .right-side {
    padding-left: 6%;
}
#blocFormulaire #right {
    left: -16.5%;
}
}

@media screen and (max-width: 1630px) {

#bloc50-50::after {
    left: 43.2%;
}
#blocEquipe .right-side {
    padding-top: 2%;
}
#blocFormulaire #right {
    left: -15.5%;
}
}

@media screen and (max-width: 1550px) {
    
.slideshow-wrap .caption {
    top: 50%;
    left: 68%;
}
#burst-texteImage .blocBurst .burst {
    width: 27%;
}
#blocVideo-fetes .video {
    width: 50%;
    margin-right: 100px;
}
#blocVideo-fetes .video iframe {
    width: 630px;
    height: 350px;
}
#blocVideo-fetes .fetes {
    border-radius: 10px;
}
#bloc50-50::after {
    left: 42.8%;
}
#blocEquipe .right-side {
    padding-top: 1%;
}
#burst-texteImage .texte-image .left-side {
    width: 52%;
    margin-right: 100px;
}
#burst-texteImage .texte-image .left-side h2 {
    font-size: 2rem;
}
#blocServices .bloc.left .right-side {
    padding-left: 5%;
}
#blocServices ul li {
    font-size: 0.9rem;
}
#blocServices h3 {
    font-size: 2.2rem;
    padding-bottom: 15px;
}
#blocServices .bloc.right .left-side {
    padding-left: 3%;
}
#blocActivites .realisations.zoom-gallery a.item {
    width: 50%;
}
#blocActivites .bloc .texte {
    width: 95%;
}
#blocActivites .bloc .texte h4 {
    font-size: 1.3rem;
}
#blocFormulaire label {
    font-size: 1.2rem;
}
#blocFormulaire label.container {
    font-size: 1rem;
}
#blocFormulaire #right {
    left: -14.5%;
}
#blocServices ul li {
    padding-bottom: 5px;
}
}

@media screen and (max-width: 1460px) {
    
#burst-texteImage .texte-image .left-side h2 {
    font-size: 1.8rem;
}
#burst-texteImage .texte-image .left-side h3 {
    font-size: 2.3rem;
}
#blocEquipe .right-side {
    padding-top: 0%;
}
#bloc50-50::after {
    left: 42.2%;
}
#blocFormulaire #right {
    left: -13.5%;
}
}

@media screen and (max-width: 1425px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    font-size: 0.9rem;
    margin: -5px;
}
.slideshow-wrap .caption {
    top: 45%;
    left: 70%;
}
#blocCoordonnees {
    padding: 100px 0%;
}
#blocCoordonnees .bloc {
    width: 30%;
}
#blocFormulaire label {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 1390px) {

#blocServices .bloc.right .blocListe .left {
    width: 55%;
}
#burst-texteImage .blocBurst.remplissage {
    margin-top: -55px;
    padding-bottom: 105px;
}
.module-header--caption {
    top: 60%;
}
#blocFormulaire #right {
    left: -12.5%;
}
}

@media screen and (max-width: $size-md-max) {
    
#burst-texteImage .blocBurst .burst {
    width: 30%;
}
.slideshow-wrap .caption {
    top: 45%;
    left: 72%;
}
#blocEquipe .left-side {
    width: 50%;
    margin-right: 100px;
}
#blocEquipe .right-side {
    padding-top: 2%;
}
#bloc50-50::after {
    left: 41.5%;
}
#blocVideo-fetes .fetes {
    width: 55%;
}
#blocVideo-fetes .video iframe {
    width: 528px;
    height: 300px;
}
#burst-texteImage .texte-image .left-side {
    width: 60%;
    margin-right: 70px;
}
.module-header--caption {
    top: 57%;
}
#blocActivites {
    padding: 75px 5%;
}
#blocActivites .bloc .texte {
    width: 95%;
}
#blocFormulaire #right {
    left: -10.5%;
}
}

@media screen and (max-width: 1250px) {
   
#bloc50-50::after {
    display: none;
}
.module-header--caption {
    top: 50%;
}
#blocCoordonnees {
    padding: 100px 2%;
}
#blocCoordonnees .bloc p {
    font-size: 1.1rem;
}
#blocFormulaire #right {
    left: -8.5%;
}
}

@media screen and (max-width: 1150px) {
  
#blocServices .bloc {
    flex-direction: column;
    padding-bottom: 25px;
} 
#blocServices .bloc .left-side, #blocServices .bloc.right .right-side {
    margin: auto;
}
#blocServices .bloc.left .right-side, #blocServices .bloc.right .left-side {
    margin: auto;
    padding-top: 10px;
    padding-left: 0;
}
#blocServices .bloc.right {
    flex-direction: column-reverse;
}
#blocServices {
    padding: 100px 2% 75px 2%;
}
#burst-texteImage .blocIntro_installations p br {
    display: none;
}
#blocFormulaire #right {
    left: -6.5%;
}
}

@media screen and (max-width: 1125px) {

#blocCoordonnees {
    padding: 50px 2%;
    flex-direction: column;
}
#blocCoordonnees .bloc {
    width: 50%;
    margin: auto;
    padding-bottom: 45px;
}
}

@media screen and (max-width: 1024px) {
   
#burst-texteImage .blocBurst .burst {
    min-height: 250px;
}
#burst-texteImage .blocBurst {
    margin-top: -70px;
    padding-bottom: 100px;
}
#burst-texteImage .blocBurst .burst .bloc p {
    font-size: 0.9rem;
}
#burst-texteImage .blocBurst .burst .bloc h3 {
    font-size: 1.3rem;
}
#burst-texteImage .texte-image {
    flex-direction: column;
}
#burst-texteImage .texte-image .left-side {
    width: 100%;
    margin-right: 0;
    text-align: left;
    padding-bottom: 65px;
}
#blocEquipe .right-side {
    padding-top: 0%;
}
#blocEquipe {
    padding: 50px 5%;
}
#blocEquipe .left-side {
    width: 60%;
    margin-right: 35px;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .right-side {
    width: 100%;
    padding: 50px 5%;
}
#bloc50-50 .left-side {
    background: #18aff6;
    width: 100%;
    padding: 50px 5%;
    text-align: left;
}
#bloc50-50 p {
    width: 100%;
}
#bloc50-50 .left-side h3 {
    padding-right: 0;
}
#blocVideo-fetes {
    flex-direction: column;
    padding: 60px 3%;
}
#blocVideo-fetes .video iframe {
    width: 615px;
    height: 343px;
}
#blocVideo-fetes .video {
    width: 60%;
    margin-right: 0;
}
#blocVideo-fetes .fetes {
    width: 82%;
    margin-top: 35px;
}
footer .footer-wrapper .blocMenu a {
    padding-right: 30px;
}
footer .footer-wrapper .blocCopyright .copyright-mediaweb .copyright {
    width: 40%;
}
#blocServices .bloc .right-side {
    width: 65%;
}
#blocServices .bloc .left-side {
    width: 65%;
}
#burst-texteImage .blocBurst.remplissage {
    margin-top: -35px;
    padding-bottom: 75px;
}
#blocNosInstallations .blocListe .left-side {
    width: 73%;
}
#blocFormulaire {
    padding: 75px 3% 0 3%;
}
#blocFormulaire #right {
    left: -4.5%;
}
}

@media screen and (max-width: $size-sm-max) {
   
#burst-texteImage .blocBurst {
    flex-direction: column;
    margin-top: -45px;
}
#burst-texteImage .blocBurst .burst.center {
    margin: 15px auto;
}
#burst-texteImage .blocBurst .burst {
    width: 65%;
    margin: auto;
}
.slideshow-wrap .caption {
    top: 40%;
    left: 62%;
}
#blocVideo-fetes .video {
    width: 85%;
}
footer .footer-wrapper .blocMenu a {
    font-size: 0.8rem;
    padding-right: 15px;
}
#blocEquipe .left-side {
    display: none;
}
#blocEquipe .right-side {
    width: 100%;
}
#blocEquipe {
    margin-bottom: 0;
}
#blocServices {
    padding: 50px 2% 25px 2%;
}
#blocServices .bloc .right-side, #blocServices .bloc .left-side {
    width: 75%;
}
.module-header--caption .img-responsive {
    width: 50%;
}
#blocNosInstallations .blocListe .left-side {
    width: 100%;
    margin-bottom: -10px;
}
#blocNosInstallations .blocListe {
    flex-direction: column;
}
#blocImages a.image {
    width: 50%;
}
#blocNosInstallations {
    padding: 50px 5%;
}
#blocActivites .realisations.zoom-gallery a.item {
    width: 100%;
    padding-bottom: 45px;
}
#blocFormulaire .formulaireDemande {
    flex-direction: column;
}
#blocFormulaire .formulaireDemande .left-side, #blocFormulaire .formulaireDemande .right-side {
    width: 100%;
}
#blocFormulaire #right {
    position: initial;
    justify-content: initial;
}
}

@media screen and (max-width: $size-xs-max) {

#blocVideo-fetes .video {
    width: 100%;
}
#blocVideo-fetes .video iframe {
    width: 390px;
    height: 220px;
}
#blocVideo-fetes .fetes {
    width: 100%;
    margin-top: 35px;
    min-height: 335px;
}
#blocVideo-fetes .fetes .img-responsive {
    padding: 10px 20px 5px 20px;
}
footer .footer-wrapper .blocMenu {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}
footer .footer-wrapper .blocMenu a {
    font-size: 0.9rem;
    padding-right: 0;
    padding-bottom: 10px;
}
footer {
    background: #18aff6;
    min-height: 375px;
}
footer .footer-wrapper .blocCopyright {
    padding-top: 30px;
    border-top: 1px solid #fff;
}
footer .footer-wrapper .blocCopyright {
    flex-direction: column-reverse;
}
footer .footer-wrapper .blocCopyright .copyright-mediaweb {
    padding-top: 25px;
    flex-direction: column;
}
footer .footer-wrapper .blocCopyright .copyright-mediaweb .copyright {
    width: 100%;
    padding-bottom: 15px;
}
#burst-texteImage .blocBurst .burst {
    width: 85%;
}
.slideshow-wrap .caption {
    top: 40%;
    left: 48%;
    width: 80%;
}
#burst-texteImage .texte-image .left-side h2 {
    font-size: 1.5rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 120px;
}
#blocServices .bloc .right-side, #blocServices .bloc .left-side {
    width: 100%;
}
#blocServices .bloc.right .blocListe .left {
    width: 75%;
}
#blocServices h3 {
    font-size: 2rem;
    padding-bottom: 20px;
}
#blocServices .bloc.left .right-side, #blocServices .bloc.right .left-side {
    padding-top: 20px;
}
#blocInfo {
    padding: 50px 2%;
}
.module-header--caption .img-responsive {
    width: 85%;
}
#blocActivites {
    padding: 50px 2%;
}
#blocActivites .bloc .texte {
    width: 100%;
}
#burst-texteImage .blocIntro h3 {
    line-height: 1;
}
#burst-texteImage .blocIntro p {
    font-size: 2rem;
    line-height: 1.3;
    padding-top: 20px;
}
#blocCoordonnees .bloc {
    width: 65%;
}
}

@media screen and (max-width: 360px) {

#blocVideo-fetes .fetes {
    min-height: 360px;
}
#blocVideo-fetes .video iframe {
    width: 335px;
    height: 195px;
}
#bloc50-50 .right-side .liste .left {
    width: 75%;
}
#blocVideo-fetes .fetes .bloc p {
    font-size: 1.1rem;
}
#blocServices .bloc.right .blocListe .left {
    width: 100%;
}
#blocServices h3 {
    font-size: 1.8rem;
}
#blocCoordonnees .bloc {
    width: 75%;
    padding-bottom: 35px;
}
}